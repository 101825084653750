import Button from '../../../../common/Button'
import ColorPicker from '../../../../common/ColorPicker'
import { Input, CheckBox } from '../../../../common/FormElement'
import Switch from '../../../../common/Switch'
import DropdownArrowsPicker from '../../../lib/components/DropdownArrowsPicker'
import * as styles from '../assets/styles'

const CONTACT_US_OPTIONS = {
  CALL: 'Call',
  EMAIL: 'Email',
  HIDE: 'Hide',
}

const SHOW_ARRIVAL_TIME_OPTIONS = {
  RANGE: 'Range (5 - 10 min)',
  TIME: 'Time (12:30PM - 1:00PM)',
}

const WAIT_TIME_BUFFER_OPTIONS = {
  0: 'None',
  5: '5',
  10: '10',
  15: '15',
  20: '20',
  30: '30',
}

const _isValidWidgetSettings = errorsDict => {
  for (const key in errorsDict) {
    if (errorsDict[key]) {
      return false
    }
  }
  return true
}

const _save = (actions, appState, widgetSettings, _) => {
  if (_isValidWidgetSettings(appState.errors)) {
    actions.tryPostWidgetSettings(widgetSettings)
  }
}

const _changeField = (actions, fieldName, e) => {
  actions.updateField(fieldName, e.target.value)
}

const _changeFieldWithValue = (actions, widgetSettings, fieldName, val, _) => {
  actions.updateField(fieldName, val)
}

const _onNumberBlurHandler = (fieldId, minNum, maxNum, e) => {
  let value = parseInt(e.target.value, 10)
  value = Math.min(Math.max(value, minNum), maxNum)
  $(`#${fieldId}`).val(value)
}

const _changeHexColor = (actions, fieldName, color) => {
  actions.updateField(fieldName, color.hex)
}

const getCopyCode = (widgetSettings, venue) => {
  const type = widgetSettings.buttonId % 2 === 1 ? 'dark' : 'light'
  let size
  if (widgetSettings.buttonId < 3) {
    size = 'sm'
  } else if (widgetSettings.buttonId < 5) {
    size = 'md'
  } else {
    size = 'lg'
  }
  const color = `sr-${widgetSettings.buttonColor}`

  return [
    `<div id="sr-waitlist-root" class="sr-${size} sr-${type} ${color}">${widgetSettings.textButton}</div>`,
    `<script src="https://${window.location.host}/widget/embed.js"></script>`,
    '<script>',
    'SevenroomsWidget.init({',
    `    venueId: "${venue}",`,
    `    triggerId: "sr-waitlist-root", // id of the dom element that will trigger this widget`,
    `    type: "waitlist", // either 'waitlist' or 'reservations' or 'events'`,
    `    styleButton: true, // true if you are using the SevenRooms button`,
    `    previewWaitTimes: ${widgetSettings.showWaitTimes} // true if you are using the SevenRooms button & previewing wait times`,
    '})',
    '</script>',
  ].join('\n')
}

const getLinkText = venue => `${window.location.host}/waitlist/${venue}`
const getReservationsWidgetSettingsLink = venue => `/manager/${venue}/settings/widgets/dining`
const getProfileInformationSettingsLink = venue => `/manager/${venue}/venueprofile`

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const TextEntry = ({ name, label, widgetSettings, appState, actions, width = 150, marginLeft = 0 }) => {
  const _css = {
    width: `${width}px`,
    marginBottom: '5px',
  }

  const _value = widgetSettings[name]

  return (
    <div style={{ marginLeft: `${marginLeft}px` }}>
      <p className="group-label">{label}</p>
      <Input
        inputCss={_css}
        value={_value}
        onChangeHandler={_changeField.bind(this, actions, name)}
        disabled={appState.isGetting || appState.isPosting}
      />
      <div className="float-end" />
    </div>
  )
}

export const WidgetSettingsForm = ({ widgetSettings, appState, actions }) => {
  const venue = window.location.pathname.split('/')[2]
  return (
    <form style={styles.Form} className="styled" data-sr-validate>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>General</div>
        <div style={styles.SubHeader}>Formatting</div>
        <div className="inline">
          <p style={styles.Caption}>
            Waitlist widget mirrors the formatting set up in the &nbsp;
            <a href={getReservationsWidgetSettingsLink(venue)}>Reservation widget settings</a>&nbsp; page.
          </p>
        </div>
        <div className="float-end" />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Widget button settings</div>

        <p className="group-label">Customize text on button</p>
        <Input
          name="custom_button_text"
          inputCss={{ marginBottom: '10px', width: '299px' }}
          value={widgetSettings.textButton}
          onChangeHandler={_changeField.bind(this, actions, 'textButton')}
          disabled={appState.isGetting || appState.isPosting}
        />
        <div className="float-end" />
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Characters</p>
          </div>
          <div style={styles.SettingCol} className="radioset">
            {widgetSettings.textButton !== widgetSettings.textButton.toUpperCase() ? (
              <div className="inline" style={{ marginBottom: '5px' }}>
                <div
                  style={styles.CharBoxFirst}
                  onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'textButton', widgetSettings.textButton.toUpperCase())}
                >
                  AA
                </div>
                <div style={{ ...styles.CharBoxLast, ...styles.BlueCharBox }}>Aa</div>
              </div>
            ) : (
              <div className="inline" style={{ marginBottom: '5px' }}>
                <div style={{ ...styles.CharBoxFirst, ...styles.BlueCharBox }}>AA</div>
                <div
                  style={styles.CharBoxLast}
                  onClick={_changeFieldWithValue.bind(
                    this,
                    actions,
                    widgetSettings,
                    'textButton',
                    capitalizeFirstLetter(widgetSettings.textButton)
                  )}
                >
                  Aa
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="float-end" />
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Button color</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.buttonColor, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'buttonColor')}
            />
          </div>
        </div>
        <div className="float-end" />
        <div style={styles.SettingRow}>
          <div style={{ ...styles.SettingColTitle, lineHeight: '25px' }}>
            <span className="switch-label">Show wait times</span>
          </div>
          <div style={styles.SettingCol}>
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'showWaitTimes', !widgetSettings.showWaitTimes)}
              value={!widgetSettings.showWaitTimes}
            />
          </div>
        </div>

        <div className="float-end" />
        <p className="group-label" style={{ margin: '13px 0 7px' }}>
          Select which button you would like to use on your website
        </p>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(1, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 1)}
            >
              <div style={styles.ButtonStyle('small', 'dark', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('small'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '10px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('small')}>
                    <i style={styles.ButtonImg('tiny')} className="svr-icon- svr-icon--timer-line" />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '25px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(2, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 2)}
            >
              <div style={styles.ButtonStyle('small', 'light', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('small'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '10px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('small')}>
                    <i style={styles.ButtonImg('tiny')} className="svr-icon- svr-icon--timer-line" />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '25px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(3, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 3)}
            >
              <div style={styles.ButtonStyle('medium', 'dark', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('medium'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '6px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('medium')}>
                    <i
                      style={{
                        ...styles.ButtonImg('small'),
                        ...(widgetSettings.showWaitTimes ? { marginRight: '5px' } : {}),
                      }}
                      className="svr-icon- svr-icon--timer-line"
                    />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '29px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(4, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 4)}
            >
              <div style={styles.ButtonStyle('medium', 'light', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('medium'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '6px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('medium')}>
                    <i
                      style={{
                        ...styles.ButtonImg('small'),
                        ...(widgetSettings.showWaitTimes ? { marginRight: '5px' } : {}),
                      }}
                      className="svr-icon- svr-icon--timer-line"
                    />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '29px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(5, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 5)}
            >
              <div style={styles.ButtonStyle('large', 'dark', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('large'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '10px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('large')}>
                    <i
                      style={{
                        ...styles.ButtonImg('medium'),
                        ...(widgetSettings.showWaitTimes ? { marginRight: '5px' } : {}),
                      }}
                      className="svr-icon- svr-icon--timer-line"
                    />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '34px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(6, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 6)}
            >
              <div style={styles.ButtonStyle('large', 'light', widgetSettings.buttonColor, widgetSettings.showWaitTimes)}>
                <i
                  style={{
                    ...styles.ButtonImg('large'),
                    ...(widgetSettings.showWaitTimes ? { marginTop: '10px', marginLeft: '10px' } : {}),
                  }}
                  className="sr-fa sr-fa-logo"
                />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.textButton}</span>
                {widgetSettings.showWaitTimes && (
                  <div style={styles.ButtonCaptionStyle('large')}>
                    <i
                      style={{
                        ...styles.ButtonImg('medium'),
                        ...(widgetSettings.showWaitTimes ? { marginRight: '5px' } : {}),
                      }}
                      className="svr-icon- svr-icon--timer-line"
                    />
                    <span style={{ ...styles.ButtonText, maxWidth: '87px', marginRight: '34px' }}>0 - 15 min</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inline">
          <p style={styles.Note}>
            In order for the selected widget button settings to take effect,
            <br />
            you will need to be sure the snippet below matches the snippet on your website.
          </p>
        </div>
        <div className="float-end" />

        <div style={{ ...styles.MiniSubHeader, margin: '22px 0 8px' }}>Links</div>
        <p className="group-label">Copy and paste this code into your website</p>
        <textarea style={{ width: '540px', height: '161px', resize: 'none' }} readOnly value={getCopyCode(widgetSettings, venue)} />
        <p className="group-label">Direct link to widget</p>
        <div className="inline">
          <textarea style={{ width: '450px', height: '17px', resize: 'none' }} readOnly value={getLinkText(venue)} />
        </div>
        <div className="float-end" />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Search Configurations</div>
        <div style={styles.MiniSubHeader}>Additional Search Configurations</div>
        <div className="input-height">
          <div className="input-col input-height">
            <Input
              id="min_number_guests"
              labelText="Min number of guests"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.minGuests}
              min={1}
              max={20}
              onChangeHandler={_changeField.bind(this, actions, 'minGuests')}
              onBlurHandler={_onNumberBlurHandler.bind(this, 'min_number_guests', 1, 20)}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
          <div className="input-col input-height">
            <Input
              id="max_number_guests"
              labelText="Max number of guests"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.maxGuests}
              min={1}
              max={20}
              onChangeHandler={_changeField.bind(this, actions, 'maxGuests')}
              onBlurHandler={_onNumberBlurHandler.bind(this, 'max_number_guests', 1, 20)}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
        </div>
      </div>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Widget Configurations</div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show venue banner</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'showVenueBanner', !widgetSettings.showVenueBanner)}
              value={!widgetSettings.showVenueBanner}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Text Marketing Opt-in for Venue</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'venueSmsMarketingOn', !widgetSettings.venueSmsMarketingOn)}
              value={!widgetSettings.venueSmsMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueSmsMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_sms_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSmsMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSmsMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSmsMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Venue</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueSpecificMarketingOn',
                !widgetSettings.venueSpecificMarketingOn
              )}
              value={!widgetSettings.venueSpecificMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueSpecificMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSpecificMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSpecificMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSpecificMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Group</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueGroupMarketingOn',
                !widgetSettings.venueGroupMarketingOn
              )}
              value={!widgetSettings.venueGroupMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueGroupMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_group_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueGroupMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueGroupMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueGroupMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show special attention message</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'showWaitlistSpecialAttentionMessage',
                !widgetSettings.showWaitlistSpecialAttentionMessage
              )}
              value={!widgetSettings.showWaitlistSpecialAttentionMessage}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Hide quote times from guests</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'hideQuoteTimes', !widgetSettings.hideQuoteTimes)}
              value={!widgetSettings.hideQuoteTimes}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {!widgetSettings.hideQuoteTimes && (
          <div>
            <div className="settings-height">
              <div className="settings-col settings-height">
                <span className="switch-label">Minimum wait shown</span>
              </div>
              <div className="settings-col settings-height">
                <Input
                  id="minimum-wait-shown"
                  type="number"
                  inputCss={{ width: 30 }}
                  value={widgetSettings.minimumWaitShown}
                  min={0}
                  onChangeHandler={_changeField.bind(this, actions, 'minimumWaitShown')}
                  validator_type="int_positive"
                  validator_message="Enter a number"
                />
                <span>&nbsp; min</span>
              </div>
            </div>
            <div className="settings-height">
              <div className="settings-col settings-height" style={{ width: '195px' }}>
                <span className="switch-label">Wait Time Buffer</span>
              </div>
              <div className="settings-col settings-height">
                <DropdownArrowsPicker
                  key="WAIT_TIME_BUFFER"
                  name="waitTimeBuffer"
                  choices={Object.entries(WAIT_TIME_BUFFER_OPTIONS).map(([key, val]) => ({ name: val, value: key }))}
                  noHeader
                  value={widgetSettings.waitTimeBuffer}
                  isMultiSelect={false}
                  onChangeHandler={val => _changeFieldWithValue(actions, widgetSettings, 'waitTimeBuffer', val)}
                  style={{ float: 'left', width: '100px' }}
                  disabled={appState.isGetting || appState.isPosting}
                  isLightTheme
                  showNavigation={false}
                />
              </div>
            </div>
            <div className="settings-height" style={{ marginTop: '12px' }}>
              <div className="settings-col settings-height" style={{ width: '149px' }}>
                <span className="switch-label">Show arrival time</span>
              </div>
              <div className="settings-col settings-height">
                <DropdownArrowsPicker
                  key="SHOW_ARRIVAL_TIME_TYPE"
                  name="showArrivalTimeType"
                  choices={Object.entries(SHOW_ARRIVAL_TIME_OPTIONS).map(([key, val]) => ({ name: val, value: key }))}
                  noHeader
                  value={widgetSettings.showArrivalTimeType}
                  isMultiSelect={false}
                  onChangeHandler={val => _changeFieldWithValue(actions, widgetSettings, 'showArrivalTimeType', val)}
                  style={{ float: 'left', width: '200px' }}
                  disabled={appState.isGetting || appState.isPosting}
                  isLightTheme
                  showNavigation={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Waiting Room Configurations</div>
        <div>
          <p style={styles.Caption}>
            Manage menu & contact information in &nbsp;
            <a href={getProfileInformationSettingsLink(venue)}>Profile Information Settings</a>
          </p>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show reservation link</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'showReservationLink', !widgetSettings.showReservationLink)}
              value={!widgetSettings.showReservationLink}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show menu</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'showMenu', !widgetSettings.showMenu)}
              value={!widgetSettings.showMenu}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show map</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'showMap', !widgetSettings.showMap)}
              value={!widgetSettings.showMap}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show special attention message</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'showWaitingRoomSpecialAttentionMessage',
                !widgetSettings.showWaitingRoomSpecialAttentionMessage
              )}
              value={!widgetSettings.showWaitingRoomSpecialAttentionMessage}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height" style={{ width: '200px' }}>
            <span className="switch-label">Contact us</span>
          </div>
          <div className="settings-col settings-height">
            <DropdownArrowsPicker
              key="CONTACT_US"
              name="contactUs"
              choices={Object.entries(CONTACT_US_OPTIONS).map(([key, val]) => ({ name: val, value: key }))}
              noHeader
              value={widgetSettings.contactUs}
              isMultiSelect={false}
              onChangeHandler={val => _changeFieldWithValue(actions, widgetSettings, 'contactUs', val)}
              style={{ float: 'left' }}
              disabled={appState.isGetting || appState.isPosting}
              isLightTheme
              showNavigation={false}
            />
          </div>
        </div>
        <div className="float-end" />
      </div>
      <div style={styles.FormSection}>
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
      </div>
    </form>
  )
}
